import React, { Component } from 'react';
import { NavLink, Link, withRouter } from "react-router-dom";

import config from './../config';

class PageMenu extends Component {
	constructor(props) {
		super(props);

		this.url = config.vefurRoot+'/api/pages/';

		this.state = {
			listData: []
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.site && prevProps.site != this.props.site) {
			this.fetchData();
		}
	}

	fetchData() {
		// Sæki gögn til APA
		this.setState({
			listData: []
		});

		fetch(this.url+'?site='+(this.props.site || 1))
			.then(function(response) {
				return response.json();
			})
			.then(function(json) {
				this.setState({
					listData: json.results
				});
			}.bind(this));
	}

	render() {
		return (
			<React.Fragment>
				<div className={'menu-links'+(this.props.className ? ' '+this.props.className : '')}>
					{
						this.state.listData.length > 0 &&
						this.state.listData.map(function(item, index) {
							return <NavLink key={index}
								exact to={item.url}
								className={(this.props.btnLinks ?
									'inline-block mr-4 px-2 py-1 bg-white/10 hover:bg-white/60 transition-all rounded ' :
									'block mb-4 pl-4 hover:bg-arnastofnun/20 hover:outline hover:outline-4 hover:outline-arnastofnun/20'
								)+(item.menu_separator ? ' separator' : '')}
							>
								{item.title}
							</NavLink>;
						}.bind(this))
					}

				</div>
			</React.Fragment>
		);
	}
}

export default PageMenu;
