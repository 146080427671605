import React, { Component } from 'react';
import { Link } from "react-router-dom";

class ToggleSwitch extends Component {
	constructor(props) {
		super(props);
	}

	clickHandler() {
		if (this.props.onChange) {
			this.props.onChange({
				target: {
					type: 'checkbox',
					name: this.props.name || '',
					checked: !this.props.value
				}
			});
		}
	}

	render() {
		return (
			<div className={'toggle-switch '+(this.props.value ? 'switch-on' : 'switch-off')} onClick={function() {
				this.clickHandler();
			}.bind(this)} onKeyDown={function(event) {
				if (event.keyCode == 32) {
					event.preventDefault();
					this.clickHandler();
				}
			}.bind(this)}>
				{
					this.props.label &&
					<div className="label">{this.props.label}</div>
				}

				<div tabIndex={this.props.tabIndex} className="switch">
					<div className="button"><span className="icon" /></div>
				</div>
			</div>
		);
	}
}

export default ToggleSwitch;
