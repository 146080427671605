import { Link } from "react-router-dom";
import _ from 'underscore';

function RelFlList(props) {
	return props.data && ((props.data.rel && props.data.rel[props.relation]) || (props.data.rel_reversed && props.data.rel_reversed[props.relation])) &&
		<div className="p-6">
			<h4>{props.title}</h4>
			<div className="pt-2">

				{
					_.filter(props.data.rel[props.relation], item => item.rel_flid).map((item, index) => <Link key={index}
						to={'/faersla/'+item.rel_flid.id}
						className="btn-small bg-white mr-2 relative pl-7"
						target={props.isEmbed ? '_parent' : null}
						onMouseEnter={(event) => props.linkMouseOver ? props.linkMouseOver(item.rel_flid.id, event) : null}
						onMouseOut={(event) => props.linkMouseOut ? props.linkMouseOut(event) : null}>
							<span className="absolute inline-block text-xl font-bold h-0 top-[-5px] left-1 pointer-events-none">&rarr;</span>
							<span className="pointer-events-none" dangerouslySetInnerHTML={{__html: item.rel_flid.uppflettiord[0].ritmynd}} />{(item.rel_flid.uppflettiord[0].radnumer ? ' ('+item.rel_flid.uppflettiord[0].radnumer+')' : '')}
						</Link>)
				}

				{
					/*
					props.data.rel_reversed && props.data.rel_reversed[props.relation] && <div className="bold text-sm mt-2 mb-2">Tengingar hingað:</div>
					*/
				}
				<div className="clear-both" />
				{
					_.filter(props.data.rel_reversed[props.relation], item => item.flid).map((item, index) => <Link key={index}
						to={'/faersla/'+item.flid.id}
						className="btn-small bg-white mr-2 relative pl-7"
						target={props.isEmbed ? '_parent' : null}
						onMouseEnter={(event) => props.linkMouseOver ? props.linkMouseOver(item.flid.id, event) : null}
						onMouseOut={(event) => props.linkMouseOut ? props.linkMouseOut(event) : null}>
							<span className="absolute inline-block text-xl font-bold h-0 top-[-5px] left-1 pointer-events-none">&larr;</span>
							<span className="pointer-events-none" dangerouslySetInnerHTML={{__html: item.flid.uppflettiord[0].ritmynd}} />{(item.flid.uppflettiord[0].radnumer ? ' ('+item.flid.uppflettiord[0].radnumer+')' : '')}
						</Link>)
				}

			</div>
		</div>
}

export default RelFlList;
