import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import config from './../config';
import _ from 'underscore';

function UppflettiordList(props) {
	const [data, setData] = useState(null);

	useEffect(() => {
		fetch(config.apiRoot+'/api/uppflettiord/?offset='+(props.offset-10)+'&limit=40')
			.then(res => res.json())
			.then(json => {
				setData(json.results)
			});
	}, [props.offset]);

	return <div className="card bg-bl !p-0 !border !border-gray card-narrow font-junicode">
		{
			data && data.map((item, index) => <Link key={index} className={'block px-3 py-2 hover:bg-blue/30'+
				(item.rownum == props.offset ? ' btn-base -ml-2 -mr-2 bg-white' : '')+
				(data[index-1] && data[index-1].faersla == item.faersla ? ' indent-2.5 bg-white/50 text-sm italic' : '')}
				to={'/faersla/'+item.faersla} dangerouslySetInnerHTML={{__html: item.ritmynd+(item.radnumer ? ' <small>('+item.radnumer+')</small>' : '')}} />)
		}
	</div>
}

export default UppflettiordList;
