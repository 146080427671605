import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import _ from 'underscore';

import helpers from './../utils/helpers';
import config from './../config';

function PaginationButtons(props) {
	let pageButtons = [];
	let navigate = useNavigate();
	let location = useLocation();

	let maxButtonCount = 7;

	let currentPage = parseInt(location.search != '' ? helpers.parseSearchParams(location.search).sida || 1 : 1);

	let startPage = currentPage;

	let pageSize = props.pageSize || config.pageSize;

	if (startPage > Math.floor(maxButtonCount/2) && props.resultCount/pageSize > maxButtonCount) {
		startPage = startPage-(Math.floor(maxButtonCount/2));
	}
	else {
		startPage = 1;
	}

	let maxPageCount = Math.ceil((props.resultCount/pageSize))+1;

	maxPageCount = maxPageCount > startPage+maxButtonCount ? startPage+maxButtonCount : maxPageCount;
	maxPageCount = maxPageCount > props.resultCount/pageSize ? Math.ceil(props.resultCount/pageSize) : maxPageCount;

	const buildUrl = (page) => {
		return location.pathname+helpers.updateSearchParams(location.search, {sida: page});
	};

	let btnClass = 'btn shadow-none rounded-none first:rounded-l last:rounded-r';
	let dotsClass = 'btn rounded-none bg-gray-light shadow-none';

	if (startPage > 1) {
		pageButtons.push(
			<Link key="1" className={btnClass} to={buildUrl(1)} aria-label="Síða 1">
				<span aria-hidden="true">1</span>
			</Link>
		);

		if (startPage > 2) {
			pageButtons.push(
				<div key="..." className={dotsClass}>
					<span aria-hidden="true">...</span>
				</div>
			);
		}
	}


	for (let i = startPage; i<maxPageCount; i++) {
		pageButtons.push(
			<Link key={i} className={btnClass+(i == currentPage ? ' bg-arnastofnun text-white' : '')} to={buildUrl(i)} aria-label={'Síða '+i}>
				<span aria-hidden="true">{i}</span>
			</Link>
		);
	}

	if (Math.ceil(props.resultCount/pageSize) > maxPageCount) {
		pageButtons.push(
			<div className={dotsClass}>
				<span aria-hidden="true">...</span>
			</div>
		);
	}

	pageButtons.push(
		<Link key={Math.ceil((props.resultCount/pageSize))} className={btnClass} to={buildUrl(Math.ceil(props.resultCount/pageSize))} aria-label="Síða">
			<span aria-hidden="true">{Math.ceil((props.resultCount/pageSize))}</span>
		</Link>
	);

	return <>
		<hr className="w-full" />
		<div className={'mx-2 my-8 w-full flex justify-center'}>
			<div class="space-x-[-1px] justify-center flex shadow">
				{
					props.previousUrl &&
					<Link className={btnClass} to={buildUrl(currentPage-1)} aria-label="Fyrri síða">
						<span aria-hidden="true"><span className="arrows">&laquo;</span> Fyrri síða</span>
						<span className="sr-only">Fyrri síða</span>
					</Link>
				}
				{
					pageButtons
				}
				{
					props.nextUrl &&
					<Link className={btnClass} to={buildUrl(currentPage+1)} aria-label="Næsta síða">
						<span aria-hidden="true">Næsta síða <span className="arrows">&raquo;</span></span>
						<span className="sr-only">Næsta síða</span>
					</Link>
				}
			</div>
		</div>
	</>;
}

export default PaginationButtons;
