const helpers = {
	langAbbr: {
		'fserbó-króat.': 'fornserbó-króatíska',
		'germ.-keltn.': 'germansk-keltneskur, þ.e. germanskt orð í keltnesku',
		'keltn.-latn.': 'keltnesk-latneskur, þ.e. keltneskt orð í latínu',
		'serbó-króat.': 'serbó-króatíska',
		'finnsk-úgr.': 'finnsk-úgrískur',
		'keltn.-lat.': 'keltnesk latína, þ.e. keltneskt orð í latínu',
		'keltn.-róm.': 'keltneskt orð í rómönskum málum',
		'rússn. ksl.': 'rússnesk kirkjuslavneska',
		'síðlat.-gr.': 'grískt orð í síðlatínu',
		'þrak.-fryg.': 'þrakversk-frygíska',
		'gall.-lat.': 'gallólatína, þ.e. gallískt orð í latínu',
		'germ.-lat.': 'germanskt orð í latínu',
		'hvítrússn.': 'hvítrússneska',
		'lat.-germ.': 'germanskt orð í latínu',
		'lat.-gotn.': 'gotneskt orð í latínu',
		's.-afríska': 'suður-afríska',
		'sal.frank.': 'salísk frankíska',
		'serb.-ksl.': 'serbnesk kirkjuslavneska',
		'v.-asískur': 'vestur-asískur',
		'forkeltn.': 'forkeltneska',
		'gall.-gr.': 'gallískt orð í grísku',
		'gallóróm.': 'gallórómanska, þ.e. gallískt orð í rómönskum málum',
		'gr.-rómv.': 'grísk-rómverskur',
		'krímgotn.': 'krímgotneska',
		'alþ.lat.': 'alþýðulatína',
		'aprússn.': 'austur-prússneskur',
		'fprússn.': 'fornprússneska',
		'gr.-lat.': 'grísk-latína, þ.e. grískt orð í latínu',
		'gsvissn.': 'gömul svissneska',
		'lat.-gr.': 'grískt orð í latínu',
		'samgerm.': 'samgermanskur',
		'samnorr.': 'samnorrænn',
		'síðgerm.': 'síðgermanskur',
		'síðlatn.': 'síðlatneskur',
		'síðnorr.': 'síðnorræna',
		'afrank.': 'austur-frankíska',
		'afrísn.': 'austur-frísneska',
		'ffrank.': ' fornfrankíska',
		'ffrísn.': 'fornfrísneska',
		'fgautl.': ' forngautlenska',
		'fkeltn.': ' fornkeltneska',
		'fkymbr.': 'fornkymbríska',
		'frgerm.': 'frumgermanska',
		'frnorr.': 'frumnorræna,',
		'frússn.': 'fornrússneska',
		'fsemit.': 'fornsemitískur',
		'gr.-eg.': 'grísk-egifska, þ. e. egifskt orð í grísku',
		'grússn.': 'gömul rússneska',
		'hjaltl.': 'hjaltlenska',
		'lrússn.': 'litla rússneska, þ.e. úkraínska',
		'messap.': 'messapíska',
		'mkymbr.': 'miðkymbríska',
		'nfrísn.': 'norður-frísneska',
		'no.-ír.': 'norskt orð í írsku',
		'nrússn.': 'nýrússneska',
		'portúg.': 'portúgalska',
		'síðísl.': 'síðíslenska',
		'síðlat.': 'síðlatína',
		'svissn.': 'svissneska',
		'tokk. A': 'tokkaríska A [austur]',
		'tokk. B': 'tokkaríska B [vestur]',
		'vfrank.': 'vestur-frankíska',
		'vfrísn.': 'vestur-frísneska',
		'vslavn.': 'vestur-slavneska',
		'afinn.': 'austur-finnska',
		'agerm.': 'austur-germanska',
		'agotn.': 'austur-gotneska',
		'akkad.': 'akkadíska',
		'anorr.': 'Austur-norræna',
		'arkad.': 'arkadíska',
		'assýr.': 'assýríska',
		'avest.': 'avestíska',
		'babýl.': 'babýlónska',
		'baltn.': 'baltneskur',
		'borgh.': 'borgundarhólmska',
		'eistn.': 'eistneska',
		'etrúr.': 'etrúríska',
		'fbret.': 'fornbretónska',
		'fbúlg.': 'fornbúlgarska',
		'fgerm.': 'forngermanskur',
		'fgotl.': 'forngotlenska',
		'fíran.': 'forníranska',
		'fkorn.': 'fornkorníska',
		'flatn.': 'fornlatneskur',
		'flith.': 'fornlitháíska',
		'fnorr.': 'fornnorræna',
		'forgr.': 'forgríska',
		'forie.': 'for-indóevrópskur',
		'fpers.': 'fornpersneska',
		'frank.': 'frankískur',
		'frísn.': 'frísneska',
		'fserb.': 'fornserbneskur',
		'gholl.': 'gömul hollenska',
		'grænl.': 'grænlenska',
		'illýr.': 'illýríska',
		'keltn.': 'keltneska',
		'króat.': 'króatíska',
		'kymbr.': 'kymbríska [velska]',
		'langb.': 'langbarðamál',
		'lappn.': 'lappneska',
		'lettn.': 'lettneska',
		'maked.': 'makedóníska',
		'malaj.': 'malajíska',
		'mbret.': 'miðbretónska',
		'mbúlg.': 'miðbúlgarska',
		'mexík.': 'mexíkanska',
		'mholl.': 'miðhollenska',
		'mlatn.': 'miðaldalatneskur',
		'mordv.': 'mordvínska',
		'mpers.': 'miðpersneska',
		'namer.': 'norður-amerískur',
		'nbret.': 'nýbretónska',
		'ngerm.': 'norðurgermanska',
		'nholl.': 'nýhollenska',
		'nlatn.': 'nýlatneskur',
		'npers.': 'nýpersneska',
		'persn.': 'persneska',
		'prakr.': 'prakrít',
		'rússn.': 'rússneska',
		'samer.': 'suður-amerískur',
		'samie.': 'samindóevrópskur',
		'semit.': 'semitískur',
		'sgerm.': 'suðurgermanskur',
		'síðgr.': 'síðgríska',
		'slavn.': 'slavneskur',
		'tékkn.': 'tékkneska',
		'tíbet.': 'tíbetska',
		'tíról.': ' tírólska',
		'tyrkn.': 'tyrkneska',
		'týról.': 'týrólska',
		'venet.': 'venetíska',
		'vflæm.': 'vestur-flæmska',
		'vgerm.': 'vestur-germanskur',
		'vgotn.': 'vestur-gotneska',
		'vnorr.': 'Vestur-norræna',
		'vógúl.': 'vógúlíska',
		'alam.': 'alamanníska',
		'amer.': 'ameríska',
		'arab.': 'arabíska',
		'aram.': 'arameíska',
		'bask.': 'baskneska',
		'belg.': 'belgískur',
		'bret.': 'bretónska',
		'burg.': ' burgundamál',
		'búlg.': 'búlgarska',
		'finn.': 'finnska',
		'físl.': 'forníslenska',
		'flat.': 'fornlatína',
		'flæm.': 'flæmska',
		'fpík.': 'fornpíkaríska',
		'fpól.': 'fornpólska',
		'fryg.': 'frygíska',
		'fsax.': 'fornsaxneska',
		'gall.': 'gallíska',
		'germ.': 'germanska,',
		'gfær.': 'gömul færeyska',
		'gotl.': 'gotlenska',
		'gotn.': 'gotneska',
		'gpól.': 'gömul pólska',
		'hebr.': 'hebreska',
		'hett.': 'hettitíska',
		'holl.': 'hollenska',
		'indv.': 'indverska',
		'kald.': 'kaldeíska',
		'kínv.': 'kínverska',
		'kopt.': 'koptíska',
		'korn.': 'korníska',
		'kúrd.': 'kúrdíska',
		'latn.': 'latneskur',
		'lesb.': 'lesbíska',
		'lífl.': 'líflenska [eistneskt mál í Líflandi]',
		'lith.': 'litháíska',
		'mísl.': 'miðíslenska',
		'mlat.': 'miðaldalatína',
		'mong.': 'mongólska',
		'nísl.': 'nýíslenska, nútímaíslenska',
		'nlat.': 'nýlatína',
		'norm.': 'normannska',
		'norr.': 'norrænn',
		'orkn.': 'orkneyska',
		'próv.': 'próvensalska',
		'rómv.': 'rómverskur',
		'serb.': 'serbneska',
		'skán.': 'skánska',
		'skrt.': 'sanskrít',
		'skýþ.': 'skýþíska',
		'slóv.': 'slóvenska',
		'sorb.': 'sorbneska',
		'svab.': 'svabíska',
		'sýrl.': 'sýrlenska',
		'úmbr.': 'úmbríska',
		'ungv.': 'ungverska',
		'vafr.': 'vestur-afrískur',
		'vest.': 'vestfalska',
		'þrak.': 'þrakverska',
		'alb.': 'albanska',
		'ano.': ' austur-norska',
		'arm.': 'armenska',
		'att.': 'attíska',
		'býs.': 'býsantíska',
		'bæv.': 'bæverska',
		'dór.': 'dóríska',
		'eól.': ' eólíska',
		'feg.': 'fornegifska',
		'ffr.': 'fornfranska',
		'fgr.': 'forngríska',
		'fhþ.': 'fornháþýska',
		'fír.': 'fornírska',
		'flþ.': 'fornlágþýska',
		'fno.': 'fornnorska',
		'fsl.': 'fornslavneska',
		'fsæ.': 'fornsænska',
		'fær.': 'færeyska',
		'fön.': 'fönikíska',
		'gel.': 'gelíska',
		'gfr.': 'gömul franska',
		'ghþ.': 'gömul háþýska',
		'glþ.': 'gömul lágþýska',
		'gno.': 'gömul norska',
		'gsk.': 'gömul skoska',
		'gsæ.': 'gömul sænska',
		'ísl.': 'íslenska',
		'jap.': 'japanska',
		'jón.': 'jóníska',
		'ksl.': 'kirkjuslavneska',
		'lat.': 'latína',
		'mfr.': 'miðfranska',
		'mgr.': 'miðgríska',
		'mhþ.': 'miðháþýska',
		'mír.': 'miðírska,',
		'mlþ.': 'miðlágþýska,',
		'mno.': 'miðnorska',
		'msæ.': 'miðsænska',
		'ngr.': 'nýgríska',
		'nhþ.': 'nýháþýska',
		'nír.': 'nýírska',
		'nít.': 'norður-ítalska',
		'nlþ.': 'nýlágþýska',
		'nno.': 'nýnorska',
		'nsæ.': 'nýsænska',
		'osk.': 'oskíska',
		'oss.': ' ossetíska',
		'pól.': 'pólska',
		'róm.': 'rómanskur',
		'rsæ.': 'ríkissænska',
		'sab.': 'sabínska, sabínamál',
		'sfr.': 'suðurfranska',
		'ved.': 'vedíska',
		'vie.': 'vestur-indóevrópskur',
		'eg.': 'egifska',
		'fd.': 'forndanska',
		'fe.': 'fornenska',
		'fi.': 'fornindverska',
		'fr.': 'franska,',
		'gd.': 'gömul danska',
		'ge.': 'gömul enska',
		'gr.': 'gríska, grískur',
		'gþ.': 'gömul þýska',
		'hþ.': 'háþýska',
		'ie.': 'indóevrópska',
		'ír.': 'írska',
		'ít.': 'ítalska',
		'jó.': 'jóska',
		'lþ.': 'lágþýska',
		'md.': 'miðdanska',
		'me.': 'miðenska',
		'mi.': 'miðindverska',
		'mþ.': 'miðþýska',
		'nd.': 'nýdanska',
		'ne.': 'nýenska, nútímaenska',
		'no.': 'norska',
		//'sk.': 'skoskur',
		'sp.': 'spænska',
		'sþ.': 'suðurþýska',
		'sæ.': 'sænska',
		'd.': 'danska',
		'e.': 'enska',
		'þ.': 'þýska, þýskur'
	},

	parseSearchParams: (paramStr) => {
		let params = {};

		paramStr = decodeURI(paramStr);

		if (paramStr.substr(0, 1) == '?') {
			paramStr = paramStr.substr(1);
		}

		if (paramStr.split('&').length > 0) {
			paramStr.split('&').forEach((item, i) => {
				if (item.split('=')[0]) {
					params[item.split('=')[0]] = item.split('=')[1];
				}
			});
		}

		return params;
	},

	decodeEntities: (function() {
		// this prevents any overhead from creating the object each time
		var element = document.createElement('div');

		function decodeHTMLEntities (str) {
			if(str && typeof str === 'string') {
				// strip script/html tags
				str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
				str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
				element.innerHTML = str;
				str = element.textContent;
				element.textContent = '';
			}

			return str;
		}

		return decodeHTMLEntities;
	})(),


	searchParamString: (params) => {
		let searchParams = [];

		for (var param in params) {
			searchParams.push(param+'='+
				(Array.isArray(params[param]) ? (
					params[param].map((item) => item.id || item).join(',')
				) : params[param])
			);
		}

		return '?'+searchParams.join('&');
	},

	updateSearchParams: (paramStr, newParams) => {
		let currentParams = helpers.parseSearchParams(paramStr);

		for (var param in newParams) {
			currentParams[param] = newParams[param];
		}

		let searchParams = [];

		for (var param in currentParams) {
			if (currentParams[param] != undefined && currentParams[param] != '') {
				searchParams.push(param+'='+
					(Array.isArray(currentParams[param]) ? (
						currentParams[param].map((item) => item.id || item).join(',')
					) : currentParams[param])
				);
			}
		}

		return '?'+searchParams.join('&');
	},
}

export default helpers;
