import logo from './../img/logo-single-white.png';
import { useState, useEffect } from 'react';
import { Route, Link, useNavigate, useLocation } from "react-router-dom";
import AutocompleteInput from './AutocompleteInput';
import config from './../config';
import helpers from './../utils/helpers';
import PageMenu from './PageMenu';
import ToggleSwitch from './ToggleSwitch';

function Header(props) {
	const [searchInput, setSearchInput] = useState('');
	const [textSearchInput, setTextSearchInput] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	const executeSearch = () => {
		navigate('leit/'+searchInput+(textSearchInput ? '?texti=ja' : ''));
	}

	return <div className="bg-gray-light mb-6">
		<div className="container py-8 px-4 max-w-2xl mx-0 md:mx-auto">
			<div className="flex flex-row gap-2 mx-4 md:mx-0">
				{
					props.children
				}
				<AutocompleteInput responseDataField="results"
					searchUrl={config.apiRoot+'/api/uppflettiord/?search=$s'}
					valueField="ritmynd"
					value={searchInput}
					listLabelFormatFunc={d => helpers.decodeEntities((d.radnumer ? '<strong>'+d.radnumer+'</strong> ': '')+d.ritmynd)}
					inputValueFormatFunc={v => helpers.decodeEntities(v)}
					onEnter={event => {
						if (searchInput != '') {
							executeSearch()
						}
					}}
					onItemSelect={event => {
						navigate('faersla/'+event.faersla)
					}}
					onChange={(event) => setSearchInput(event.target.value)} />

				<button tabIndex={3} className="btn-red" onClick={() => {
					if (searchInput != '') {
						executeSearch()
					}
				}}>Leita</button>
			</div>
			<div className="pl-4 md:pl-0 pt-1 pl-20">
				<ToggleSwitch tabIndex={2} label={'Leita í texta'} onChange={(event) => setTextSearchInput(event.target.checked)} name="textSearchInput" value={textSearchInput} />
			</div>
		</div>
	</div>;
}

export default Header;
