import { useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams, Link } from "react-router-dom";
import PaginationButtons from './PaginationButtons';
import config from './../config';
import helpers from './../utils/helpers';
import _ from 'underscore';

function SearchResultsItem(props) {
	const [fullView, setFullView] = useState(false);

	let formatEntry = (entry) => {
		if (props.paramTextSearch) {
			return entry.split(props.searchTerm).join('<span class="bg-highlight/50 outline outline-highlight/20">'+props.searchTerm+'</span>')
		}
		else if (props.paramSkammstRit) {
			return entry.split(props.paramSkammstRit).join('<span class="bg-highlight/50 outline outline-highlight/20">'+props.paramSkammstRit+'</span>')
		}
		else {
			return entry;
		}
	}

	return <Link to={'/faersla/'+props.item.id+((props.paramTextSearch && props.searchTerm) || props.paramMerkja ? '?merkja='+(props.searchTerm || props.paramMerkja) : '')} className="block mb-10 pl-4 pr-4 md:pl-0 md:pr-0 w-full md:w-2/3">
		<div className="hover:bg-blue-light hover:p-4 hover:-m-4 group">
			{/*<p className="pb-4 font-junicode text-lg"dangerouslySetInnerHTML={{__html: _.uniq(_.compact(props.item.uppflettiord.map(uppflettiord => uppflettiord.ritmynd))).join(', ')}} />*/}
			<p className={'pb-4 font-junicode text-lg '+('whitespace-nowrap text-ellipsis overflow-hidden group-hover:whitespace-normal')} dangerouslySetInnerHTML={{__html: formatEntry(props.item.faersla_text)}} />
			<span className="btn bg-white" to={'/faersla/'+props.item.id}>Nánar</span>
		</div>
	</Link>
}

function SearchResults(props) {
	const navigate = useNavigate();
	let { searchTerm } = useParams();
	let [searchParams, setSearchParams] = useSearchParams();
	const [nextUrl, setNextUrl] = useState(null);
	const [previousUrl, setPreviousUrl] = useState(null);
	const [resultCount, setResultCount] = useState(0);

	const [loading, setLoading] = useState(false);

	const [currentSearchTerm, setCurrentSearchTerm] = useState(null);
	const [currentParamTSkammst, setCurrentParamTSkammst] = useState(null);
	const [currentParamTSkammstId, setCurrentParamTSkammstId] = useState(null);
	const [currentParamTOrd, setCurrentParamTOrd] = useState(null);
	const [currentParamSida, setCurrentParamSida] = useState(null);
	const [currentParamSkammstRit, setCurrentParamSkammstRit] = useState(null);
	const [currentParamTextSearch, setCurrentParamTextSearch] = useState(null);
	const [currentParamFlokkur, setCurrentParamFlokkur] = useState(null);

	const [data, setData] = useState(null);
	const [notFound, setNotFound] = useState(null);

	const paramTSkammst = searchParams.get('tskammst');
	const paramTSkammstId = searchParams.get('tskid');
	const paramTOrd = searchParams.get('tord');
	const paramSida = searchParams.get('sida');
	const paramSkammstRit = searchParams.get('rit');
	const paramTextSearch = searchParams.get('texti');
	const paramFlokkur = searchParams.get('flokkur');
	const paramMerkja = searchParams.get('merkja');
	const paramRedirect = searchParams.get('r');


	let fetchData = () => {
		setLoading(true);

		let apiParams = [];

		if (searchTerm) {
			apiParams.push('search='+searchTerm);
		}

		if (paramTSkammstId) {
			apiParams.push('tenging_skammst='+paramTSkammstId);
		}

		if (paramTOrd) {
			apiParams.push('tenging_ord='+paramTOrd);
		}
		if (paramSkammstRit) {
			apiParams.push('skammst_rit='+paramSkammstRit);
		}

		if (paramFlokkur) {
			apiParams.push('flokkur='+paramFlokkur);
		}

		if (paramTextSearch) {
			apiParams.push('text_search=true');
		}

		if (paramSida) {
			apiParams.push('limit='+config.pageSize);
			apiParams.push('offset='+(config.pageSize*(paramSida-1)))
		}

		fetch(config.apiRoot+'/api/faersla/?'+apiParams.join('&'))
			.then(res => res.json())
			.then(json => {
				setData(json.results);
				setNextUrl(json.next);
				setPreviousUrl(json.previous);
				setResultCount(json.count);

				if (json.results.length == 1 && paramRedirect != '0') {
					navigate('/faersla/'+json.results[0].id+((paramTextSearch && searchTerm) || paramMerkja ? '?merkja='+(searchTerm || paramMerkja) : ''), {
						replace: true
					});
				}

				setNotFound(json.count == 0);
				setLoading(false);
			});
	}

	useEffect(() => {
		if (searchTerm != currentSearchTerm ||
			paramTSkammst != currentParamTSkammst ||
			paramTSkammstId != currentParamTSkammstId ||
			paramTOrd != currentParamTOrd ||
			paramSida != currentParamSida ||
			paramTextSearch != currentParamTextSearch ||
			paramSkammstRit != currentParamSkammstRit ||
			paramFlokkur != currentParamFlokkur
		) {
			if (paramSida != currentParamSida) {
				window.scrollTo(0, 0);
			}

			fetchData();

			setCurrentSearchTerm(searchTerm)
			setCurrentParamTSkammst(paramTSkammst)
			setCurrentParamTSkammstId(paramTSkammstId)
			setCurrentParamTOrd(paramTOrd)
			setCurrentParamSida(paramSida)
			setCurrentParamTextSearch(paramTextSearch)
			setCurrentParamSkammstRit(paramSkammstRit)
			setCurrentParamFlokkur(paramFlokkur)
		}
	});

	return <div className={'grid grid-cols-1 place-items-center relative'+(loading ? ' loading' : '')}>
		{
			data && data.length == 0 && notFound &&  <div className="block w-full md:w-2/3 text-center">
				{
					currentSearchTerm &&
					<div className="card text-lg py-10 -ml-4 -mr-4">
						<strong>{currentSearchTerm}</strong> fannst ekki.<br/><br/>
						{
							!paramTextSearch && <Link className="btn bg-white" to={'/leit/'+currentSearchTerm+'?texti=ja'}>Leita að orðinu '{currentSearchTerm}' í texta</Link>
						}
					</div>
				}
				{
					!currentSearchTerm &&
					<div className="card text-lg py-10 -ml-4 -mr-4">
						Ekkert fannst
					</div>
				}
			</div>
		}
		{
			resultCount > 0 &&
			<div className="block w-full md:w-2/3">
				<div className="card text-lg -ml-4 -mr-4">
					<div className="card-body flex items-center text-sm">
						{
							(paramTOrd || paramTSkammst) && <div className="grow">Tengt mál: <strong>{helpers.langAbbr[paramTSkammst] || paramTSkammst}</strong>{paramTOrd && <> <i>{paramTOrd}</i></>}</div>
						}
						{
							paramSkammstRit && <div className="grow">Tengt rit: <strong>{paramMerkja}</strong></div>
						}
						{
							<div className="text-arnastofnun">{resultCount} niðurstöður</div>
						}
					</div>
				</div>
			</div>
		}
		{
			data && data.map((item, index) => <SearchResultsItem item={item} 
				key={index} 
				paramTextSearch={paramTextSearch} 
				searchTerm={searchTerm} 
				paramSkammstRit={paramSkammstRit}
				paramMerkja={paramMerkja} />)
		}
		{
			resultCount > config.pageSize &&
			<PaginationButtons resultCount={resultCount}
				previousUrl={previousUrl}
				nextUrl={nextUrl} />
		}

		{
			loading && <div className="absolute bg-white/70 inset-0" />
		}

		<div className="loading-bar">
			<div className="indicator" />
		</div>
	</div>
}

export default SearchResults;
