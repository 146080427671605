import React, { Component } from 'react';
import _ from 'underscore';
import GraphView from './GraphView';
import utils from './../utils/graphUtils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import config from './../config';

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();

		return (
			<Component
				{...props}
				location={location}
				params={params}
				navigate={navigate}
			/>
		);
	}

	return ComponentWithRouterProp;
}

class GroupGraphView extends Component {
	constructor(props) {
		super(props);

		this.relTypes = [
			{
				label: 'Sjá',
				type: 'SJA'
			},
			{
				label: 'Skylt',
				type: 'SK'
			},
			{
				label: 'Samanber',
				type: 'SBR'
			}
		]

		this.state = {
			graphData: {},
			selectedRelTypes: ['SJA', 'SK'],
			fletta1: '',
			fletta2: ''
		};
	}

	componentDidMount() {
	}

	componentDidUpdate(prevProps, prevState) {
	}

	getData(fletta1, fletta2) {
		//fetch('https://ordanet.arnastofnun.is/graph/ordsifjar/tengingar?fletta1='+this.state.fletta1+'&fletta2='+this.state.fletta2+'&type='+(this.state.selectedRelTypes.length > 0 ? this.state.selectedRelTypes : ['NONE']).join(','))
		fetch(config.neo4jApi+'/ordsifjar/tengingar?fletta1='+this.state.fletta1+'&fletta2='+this.state.fletta2)
			.then((result) => result.json())
			.then((json) => {
				this.setState({
					graphData: utils.groupGraphData(json)
				});
			});
	}

	render() {
		return <div>

			<div class="container max-w-2xl mx-auto pt-5 pb-5 mb-8 flex flex-row gap-2">
				<input type="text" placeholder="Fletta 1" value={this.state.fletta1} onChange={(event) => this.setState({fletta1: event.target.value})} />
				<input type="text" placeholder="Fletta 2" value={this.state.fletta2} onChange={(event) => this.setState({fletta2: event.target.value})} />
				<button class="btn-red" onClick={() => {
					if (this.state.fletta1 && this.state.fletta2) {
						this.getData(this.state.fletta1, this.state.fletta2);
					}
				}}>Leita</button>
			</div>


			<GraphView straightLines={true}
				distance={200}
				graphData={this.state.graphData}
				disableZoom={false}
				//highlightId={[this.props.flettaId]}
				//mainId={this.props.flettaId}
				onNodeClick={function(d) {
					if (d.properties.id) {
						this.props.navigate('/faersla/'+d.properties.id);
					}
					//this.props.history.push(helpers.routerUrls[d.label.toLowerCase()]+d.properties.ismus_id.low+(this.props.tabname ? '/'+this.props.tabname : ''));
				}.bind(this)}
				enableFullView={true}>
					<div className="flex gap-2">
						{
							this.relTypes.map(type => <button className={this.state.selectedRelTypes.indexOf(type.type) > -1 ? '!bg-arnastofnun !text-white' : ''} onClick={() => {
								let selectedRelTypes = this.state.selectedRelTypes;

								if (selectedRelTypes.indexOf(type.type) == -1) {
									selectedRelTypes.push(type.type);
								}
								else {
									selectedRelTypes.splice(selectedRelTypes.indexOf(type.type), 1);
								}

								this.setState({
									selectedRelTypes: selectedRelTypes
								}, () => {
									this.getData(this.props.flettaId);
								});
							}}>{type.label}</button>)
						}
					</div>
			</GraphView>
		</div>
	}
}


export default withRouter(GroupGraphView);
