import { useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams, useLocation, Link } from 'react-router-dom';
import WordGraphView from './WordGraphView';
import _ from 'underscore';
import config from './../config';
import RelFlList from './RelFlList';
import UppflettiordList from './UppflettiordList';
import TabContainer from './TabContainer';

function RelLangList(props) {
	return props.data &&
		<div className="p-6">
			<h4>Tengd tungumál</h4>
			<div className="pt-2">
				{
					props.data.map((item, index) => <div className="btn-small no-hover bg-white mr-2" key={index}>
						<Link target={props.isEmbed ? '_parent' : null} key={index}
							to={'/leit/?tskammst='+item.skammst.skammst+'&tskid='+item.skammst.id+'&r=0'}
							className="mr-2 hover:underline decoration-dotted underline-offset-4 decoration-1"><span className="text-arnastofnun">{item.skammst.merking}</span></Link>
						<Link target={props.isEmbed ? '_parent' : null} key={index}
							to={'/leit/?tskammst='+item.skammst.skammst+'&tskid='+item.skammst.id+(item.ord ? '&tord='+item.ord : '')+'&r=0'}
							className="hover:underline decoration-dotted underline-offset-4 decoration-1"><i>{item.ord}</i>{item.merking ? ' \''+item.merking+'\'' : ''}</Link>
					</div>)
				}
			</div>
		</div>
}

function EntryView(props) {
	let { entryId } = useParams();
	let { tab } = useParams();
	const [currentEntryId, setCurrentEntryId] = useState(null);
	const [data, setData] = useState(null);
	const [notFound, setNotFound] = useState(false);
	const [hoverInfo, setHoverInfo] = useState(null);
	const [hoverInfoId, setHoverInfoId] = useState(null);
	const [hoverInfoMax, setHoverInfoMax] = useState(false);
	const [hoverX, setHoverX] = useState(null);
	const [hoverY, setHoverY] = useState(null);
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();
	const location = useLocation();

	let [searchParams, setSearchParams] = useSearchParams();

	let fetchData = (entryId) => {
		setLoading(true);
		setHoverInfo(null);
		setHoverInfoId(null);
		setNotFound(false);

		fetch(config.apiRoot+'/api/faersla/'+entryId)
			.then(res => res.json())
			.then(json => {
				if (json.detail && json.detail == 'Not found.') {
					setNotFound(true);
					setLoading(false);
				}
				else {
					json.rel = _.groupBy(json.tengingar, 'type');
					json.rel_reversed = _.groupBy(json.tengingar_reversed, 'type');

					setData(json);
					setLoading(false);
				}
			});
	}

	const isEmbed = location.pathname.indexOf('/embed_') > -1;

	useEffect(() => {
		if (entryId != currentEntryId) {
			fetchData(entryId);
			setCurrentEntryId(entryId)
		}
	});

	useEffect(() => clearTimeout(window.hoverTimeout), [location]);

	let formatEntry = () => {
		let entry = data.faersla_text;

		data.tengingar.forEach((item, i) => {
			//if (item.rel_flid && (item.tenging == 'sja' || item.tenging == 'ath' || item.tenging == 'sk' || item.tenging == 'sbr' || item.tenging == 'tengt')) {
			if (item.rel_flid) {
				let entryLinkText = '<i>'+item.rel_flid.uppflettiord[0].ritmynd+'</i>'+(item.rel_flid.uppflettiord[0].rnum ? ' ('+item.rel_flid.uppflettiord[0].rnum+')' : '');
				entry = entry.split(entryLinkText).join('<a '+(isEmbed ? 'target="_parent"' : '')+' data-fid="'+item.rel_flid.id+'" href="/faersla/'+item.rel_flid.id+'">'+entryLinkText+'</a>');

				//if (data.tengingar[i+1] && data.tengingar[i+1].tenging == item.tenging && data.tengingar[i+1].ord == item.ord && data.tengingar[i+1].rnum == item.rnum+1) {
				//	entry = entry.replace('<i>'+item.ord+'</i> ('+item.rnum+' og '+data.tengingar[i+1].rnum+')', '<i>'+item.ord+'</i> (<a data-fid="'+item.rel_flid.faersla+'" href="/faersla/'+item.rel_flid.faersla+'">'+item.rnum+'</a> og <a data-fid="'+data.tengingar[i+1].rel_flid.faersla+'" href="/faersla/'+data.tengingar[i+1].rel_flid.faersla+'">'+data.tengingar[i+1].rnum+'</a>)')
				//}
			}
			else {
				//entry = entry.replace(/<span data-lang="([^"]+\.)"  title="([^"]+)">([^ ]+\.)<\/span> (\*)?<i([^>]*)>([^<]+)<\/i>/g, '<a title="lang" href="/leit/?tskammst=$1">$1</a> $4<a href="/leit/?tskammst=$1&tord=$6">$6</a>');
				//entry = entry.replace(/([^ ]+\.) <i([^>]*)>([^<]+)<\/i>/g, '<a title="lang" href="/leit/?tskammst=$1">$1</a> <a href="/leit/?tskammst=$1&tord=$3">$3</a>');
			}
		});
		
		entry = entry.replace(/<span title="([^"]+)">([^<]+\.)<\/span>/g, '<a title="$1" href="/leit/?tskammst=$2">$2</a>');

		data.tengingar_tungumal.forEach((item, i) => {
			entry = entry.replace('href="/leit/?tskammst='+item.skammst.skammst+'"', 'href="/leit/?tskammst='+item.skammst.skammst+'&tskid='+item.skammst.id+'"');
		})

		if (searchParams.get('merkja')) {
			entry = entry.split(searchParams.get('merkja')).join('<span class="bg-highlight/50 outline outline-highlight/20">'+searchParams.get('merkja')+'</span>')
		}

		return entry;
	};

	const relItems = [
		{
			key: 'sja',
			title: 'Sjá'
		},
		{
			key: 'sk',
			title: 'Skylt'
		},
		{
			key: 'lsk',
			title: 'Líklega skylt'
		},
		{
			key: 'sbr',
			title: 'Samanber'
		},
		{
			key: 'ath',
			title: 'Athuga'
		},
		{
			key: 'komid_ur',
			title: 'Komið úr'
		},
		{
			key: 'likl_komid_ur',
			title: 'Líklega komið úr'
		},
		{
			key: 'af_somu_rot',
			title: 'Af sömu rót'
		},
		{
			key: 'afleitt',
			title: 'Leitt af'
		}
	];

	const relItemsCount = (() => {
		let count = 0;

		relItems.forEach((relItem, i) => {
			if (data && data.rel && data.rel[relItem.key] && _.filter(data.rel[relItem.key], item => item.rel_flid).length > 0) {
				count += 1;
			}
			if (data && data.rel_reversed && data.rel_reversed[relItem.key] && _.filter(data.rel_reversed[relItem.key], item => item.flid).length > 0) {
				count += 1;
			}
		});

		return count;
	})();

	const getHoverInfo = (flid, event) => {
		window.hoverTimeout = setTimeout(() => {
			fetch(config.apiRoot+'/api/faersla/'+flid)
				.then(res => res.json())
				.then(json => {
					if (window.hoverTimeout) {
						let hoverInfo = '<strong>'+json.uppflettiord[0].ritmynd+'</strong><br/>'+
							'<p>'+json.faersla_text+'</p>';

						setHoverInfo(hoverInfo);
						setHoverInfoId(json.id);
						setHoverInfoMax(false);
						setHoverX(event.clientX);
						setHoverY(event.clientY);

						window.hoverMaxTimeout = setTimeout(() => {
							setHoverInfoMax(true);
						}, 600);
					}
				});
		}, 100);
	};

	const unsetHoverInfo = () => {
		setHoverInfo(null);
		setHoverInfoId(null);
		if (window.hoverTimeout || window.hoverMaxTimeout) {
			clearTimeout(window.hoverTimeout);
			clearTimeout(window.hoverMaxTimeout);

			window.hoverTimeout = false;
			window.hoverMaxTimeout = false;
		}
	}

	return <div className={'relative'+(loading ? ' loading' : '')}>
		{
			notFound && <div className="mx-4 block w-full text-center">
				<div className="card text-lg py-10 -ml-4 -mr-4">
					Þessi færsla fannst ekki.
				</div>
			</div>
		}
		{
			!data && loading && <>
				<div className={'flex gap-4 mb-2 entry-container'}>
					<div className={'lg:w-5/6 w-full'}>
						<h2 className="font-junicode font-bold mb-2 ml-4 sm:ml-0 bg-gray w-[250px]">&nbsp;</h2>

						<TabContainer useRouter={false}>

							<div className="" tabname="faersla" tabtitle="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;">
								<div className="block md:flex card">
									<div className={'w-full flex flex-col md:w-4/6'}>
										
										<div className="card-body !mb-0">
											<p className="entry-text font-junicode text-xl min-h-[250px]" />
										</div>

										<div className="p-6 bg-blue-light flex flex-wrap border-t border-gray divide-gray grow content-start">

										</div>

									</div>

									<div className={'w-full md:w-2/6 border-l border-gray bg-blue-light divide-y divide-gray'}></div>

								</div>
							</div>

							<div tabname="net" tabtitle="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;">
								<div className="card">
									<div className="card-body"></div>
								</div>
							</div>

						</TabContainer>
					</div>
				</div>
			</>
		}
		{
			data && <>
				<div className={'flex gap-4 mb-2 entry-container'+(isEmbed ? ' w-full' : '')}>
					<div className={(!isEmbed ? 'lg:w-5/6 ' : '')+'w-full'}>
						<h2 className="font-junicode font-bold mb-2 ml-4 sm:ml-0" dangerouslySetInnerHTML={{__html: _.uniq(_.compact(data.uppflettiord.map(uppflettiord => uppflettiord.ritmynd))).join(', ')}} />

						<TabContainer useRouter={true} selectedTab={tab} baseUrl={(isEmbed ? '/embed_faersla/' : '/faersla/')+data.id}>

							<div className="" tabname="faersla" tabtitle={'Færsla'}>
								<div className="block md:flex card">
									<div className={'w-full flex flex-col '+(relItemsCount > 0 ? 'md:w-4/6' : '')}>
										
										<div className="card-body !mb-0">
											<p className="entry-text font-junicode text-xl" dangerouslySetInnerHTML={{__html: formatEntry()}} onClick={(event) => {
												event.preventDefault();

												try {
													if (event.target.tagName.toLowerCase() == 'a' || event.target.parentElement.tagName.toLowerCase() == 'a') {
														let link;

														if (event.target.tagName.toLowerCase() == 'a') {
															link = event.target.href;
														}

														if (event.target.parentElement.tagName.toLowerCase() == 'a') {
															link = event.target.parentElement.href;
														}

														link = link.replace(window.location.origin, '');

														if (isEmbed) {
															window.open(link, '_parent');
														}
														else {
															navigate(link)
														}
													}
												}
												catch (e) {}
												}} onMouseOver={(event) => {
													event.preventDefault();

													try {
														if (event.target.tagName.toLowerCase() == 'a' || event.target.parentElement.tagName.toLowerCase() == 'a') {
															let flid;
															let fletta;

															if (event.target.tagName.toLowerCase() == 'a') {
																flid = event.target.dataset.fid;
															}
															fletta = event.target.textContent;

															if (event.target.parentElement.tagName.toLowerCase() == 'a') {
																flid = event.target.parentElement.dataset.fid;
																fletta = event.target.parentElement.textContent;
															}

															if (flid && flid != hoverInfoId) {
																getHoverInfo(flid, event);
															}
														}
														else {
															setHoverInfo(null);
															setHoverInfoId(null);
															setHoverInfoMax(false);
														}
													}
													catch (e) {
														setHoverInfo(null);
														setHoverInfoId(null);
														setHoverInfoMax(false);
													}
												}}  onMouseOut={() => {
													unsetHoverInfo();
												}}/>
											{
												data.nafnaflokkar.length > 0 && <div className="pt-4">
													{
														data.nafnaflokkar.map((item, index) => <Link target={isEmbed ? '_parent' : null} key={index} to={'/leit/?flokkur='+item.nafnaflokkur.id} className="btn-small bg-white mr-2">{item.nafnaflokkur.flokkur}</Link>)
													}
												</div>
											}
										</div>

										{
											(data.faersla_skammst.length > 0 || data.faersla_skammst_rit.length > 0) &&
											<div className="p-6 bg-blue-light flex flex-wrap border-t border-gray divide-gray grow content-start">
												{
													data.faersla_skammst.map((item, index) => <div key={index} className="w-1/2">
														<strong className="font-junicode">{item.skammst.skammst}</strong> <span className="text-gray-darker">{item.skammst.merking}</span>
													</div>)
												}
												{
													data.faersla_skammst_rit.length > 0 && <>
														<h4 className="w-full mt-4 pt-4 border-t border-gray pb-2">Rit</h4>
														{
															data.faersla_skammst_rit.map((item, index) => <Link target={isEmbed ? '_parent' : null} key={index} to={'/leit/?rit='+item.skammst_rit.id+'&merkja='+item.skammst_rit.skammst} className="btn-small bg-white mr-2 relative">
																<strong>{item.skammst_rit.skammst}</strong> <span className="text-gray-darker">{item.skammst_rit.merking}</span>
															</Link>)
														}
													</>
												}
											</div>
										}
										{
											hoverInfo &&
											<div className={'hover-info font-junicode'+(hoverInfoMax ? ' max-height' : '')}
												dangerouslySetInnerHTML={{__html: hoverInfo}}
												style={{
													top: hoverY,
													left: hoverX
												}} />
										}
									</div>
									{/*
										<div className={'grid '+
											(['grid-cols-1', 'grid-cols-2', 'grid-cols-3', 'grid-cols-4', 'grid-cols-4', 'grid-cols-4'])[relItemsCount-1]
										+' -ml-6 -mb-6 -mr-6 border-t border-b border-gray bg-blue-light divide-x divide-gray'}>
											{
												relItems.map(item => <RelFlList data={data} key={item.key} relation={item.key} title={item.title} />)
											}
										</div>

									*/}
									{
										(relItemsCount > 0 || data.tengingar_tungumal.length > 0) &&
										<div className={'w-full md:w-2/6 border-l border-gray bg-blue-light divide-y divide-gray'}>
											{
												relItemsCount > 0 && relItems.map(item => <RelFlList data={data}
													linkMouseOver={(flid, event) => getHoverInfo(flid, event)}
													linkMouseOut={() => unsetHoverInfo()}
													key={item.key}
													relation={item.key}
													title={item.title} 
													isEmbed={isEmbed} />)
											}
											{
												data.tengingar_tungumal.length > 0 &&
												<RelLangList isEmbed={isEmbed} data={data.tengingar_tungumal} />
											}
										</div>
									}
								</div>


							</div>

							<div tabname="net" tabtitle={'Venslanet'}>
								<div className="card">
									<div className="card-body">
										<WordGraphView isEmbed={isEmbed} flettaId={data.id} />
									</div>
								</div>
							</div>

						</TabContainer>

					</div>

					{
						!isEmbed && <div className="w-1/6 hidden lg:block">
							<UppflettiordList offset={data.uppflettiord[0].rownum} />
						</div>
					}					

				</div>

			</>
		}

		<div className="loading-bar">
			<div className="indicator" />
		</div>

	</div>
}

export default EntryView;
