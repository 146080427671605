import * as d3 from 'd3';
import _ from 'underscore';

export default {
	linkTypes: {
		'SBR': 'Sbr.',
		'SJA': 'Sjá',
		'SK': 'Skylt',
		'INFORMANT_AUDIO': 'Viðmælandi spyrils á hljóðrit',
		'INFORMANT_SAME_SOURCE': 'Heimildarmenn sama hljóðrits/prentaðs efnis'
	},

	linkColors: {
		'SBR': d3.schemeCategory10[0],
		'SJA': d3.schemeCategory10[1],
		'SK': d3.schemeCategory10[2],
		'INFORMANT_AUDIO': d3.schemeCategory10[3],
		'INFORMANT_SAME_SOURCE': d3.schemeCategory10[8],
		'1': d3.schemeCategory10[4],
		'2': d3.schemeCategory10[6],
		'3': d3.schemeCategory10[7],
		'4': d3.schemeCategory10[8]
	},

	groupGraphData: function(graphData) {
		return graphData;

		let connectionTypes = _.uniq(_.map(graphData.connections, function(item) {
			return item.properties.type;
		}));

		let connections = [];

		_.each(connectionTypes, function(type) {
			graphData.nodes.push({
				id: type,
				label: type,
				properties: {
					ofl: 'group',
					fletta: type
				}
			});

			_.each(graphData.connections, function(connection) {
				if (connection.properties.type == type) {
					let clone = _.clone(connection)
					clone.target = type;
					clone.source = connection.source

					connections.push(clone);
				}
			});
		});

		graphData.connections = connections;

		return graphData;
	}
}
