import React, { useState, useEffect, createRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import TabContainer from './TabContainer';
import config from './../config';

function SkammstView(props) {
	let { tab } = useParams();

	const [skammst, setSkammst] = useState(false);
	const [skammstTungumal, setSkammstTungumal] = useState(false);
	const [skammstRit, setSkammstRit] = useState(false);
	const [nafnaflokkar, setNafnaflokkar] = useState(false);

	const urlSkammst = config.apiRoot+'/api/skammst/?limit=500';
	const urlSkammstTungumal = config.apiRoot+'/api/skammst_tungumal/?limit=500';
	const urlSkammstRit = config.apiRoot+'/api/skammst_rit/?limit=500';
	const urlNafnaflokkar = config.apiRoot+'/api/nafnaflokkar/?limit=500';

	useEffect(() => {
		if (!skammst && !skammstTungumal) {
			fetchData();
		}
	});

	const fetchData = () => {
		fetch(urlSkammst)
			.then(function(response) {
				return response.json();
			})
			.then(function(json) {
				setSkammst(json.results);
			});

		fetch(urlSkammstTungumal)
			.then(function(response) {
				return response.json();
			})
			.then(function(json) {
				setSkammstTungumal(json.results);
			});


		fetch(urlSkammstRit)
			.then(function(response) {
				return response.json();
			})
			.then(function(json) {
				setSkammstRit(json.results);
			});

		fetch(urlNafnaflokkar)
			.then(function(response) {
				return response.json();
			})
			.then(function(json) {
				setNafnaflokkar(json.results);
			});
	}

	return <div className={'grid grid-cols-1 place-items-center'}>
		{
			(skammst || skammstTungumal) &&

			<div className={'ml-4 mr-4 lg:ml-0 lg:mr-0 lg:w-3/4 mt-6 html-content'}>
				<h2 className="pb-6">Skammstafanaskrár</h2>
				<TabContainer useRouter={true} alignTabs={'center'} selectedTab={tab} baseUrl={'/skammstafanir'}>
					<div tabname="tungumal" tabtitle="Tungumálaheiti" className="card">
						<div className="card-body">
							<h3 className="pb-8">Tungumálaheiti og lýsingarorð af þeim dregin</h3>
							<table className="table-auto w-full">
								{
									skammstTungumal && skammstTungumal.map((item, index) => <tr className="border-b border-gray">
										<td className="p-2"><span className="text-gray-darker"><Link to={'/leit/?tskammst='+item.skammst+'&tskid='+item.id}>{item.skammst}</Link></span></td>
										<td className="p-2 w-2/3">{item.merking}</td>
									</tr>)
								}
							</table>
						</div>
					</div>
					<div tabname="skammst" tabtitle="Skammstafanir" className="card">
						<div className="card-body">
							<h3 className="pb-8">Ýmsar skammstafanir</h3>
							<table className="table-auto w-full">
								{
									skammst && skammst.map((item, index) => <tr className="border-b border-gray">
										<td className="p-2"><span className="font-junicode text-gray-darker">{item.skammst}</span></td>
										<td className="p-2 w-2/3">{item.merking}</td>
									</tr>)
								}
							</table>
						</div>
					</div>
					<div tabname="rit" tabtitle="Rit" className="card">
						<div className="card-body">
							<h3 className="pb-8">Skrá um ýmis rit og höfundar sem vitnað er til um einstök orð, orðmyndir og merkingar</h3>
							<table className="table-auto w-full">
								{
									skammstRit && skammstRit.map((item, index) => <tr className="border-b border-gray">
										<td className="p-2"><span className="text-gray-darker">
											{
												item.has_relation && <Link to={'/leit/?rit='+item.id+'&merkja='+item.skammst}>{item.skammst}</Link>
											}
											{
												!item.has_relation && <>{item.skammst}</>
											}											
										</span></td>
										<td className="p-2 w-2/3">
											{
												item.has_relation && <Link to={'/leit/?rit='+item.id+'&merkja='+(item.skammst || item.merking)}>{item.merking}</Link>
											}
											{
												!item.has_relation && <>{item.merking}</>
											}
										</td>
									</tr>)
								}
							</table>
						</div>
					</div>
					<div tabname="flokkar" tabtitle="Nafnaflokkar" className="card">
						<div className="card-body">
							<h3 className="pb-8">Flokkar ýmissa heita sem skráð eru í bókinni</h3>
							<table className="table-auto w-full">
								{
									nafnaflokkar && nafnaflokkar.map((item, index) => <tr className="border-b border-gray">
										<td className="p-2"><span className="text-gray-darker">
											<Link to={'/leit/?flokkur='+item.id }>{item.flokkur}</Link>											
										</span></td>
									</tr>)
								}
							</table>
						</div>
					</div>
				</TabContainer>
			</div>
		}
	</div>

}

export default SkammstView;
