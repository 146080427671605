import React, { useState, useEffect, createRef } from 'react';
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";

//import BreadCrumbs from './BreadCrumbs';
//import FrontPage from './FrontPage';
import config from './../config';

function PageView(props) {
	const [initialized, setInitialized] = useState(false);
	const [data, setData] = useState(false);
	const url = config.vefurRoot+'/api/pages/';
	const location = useLocation();
	const htmlContainer = createRef();
	const navigate = useNavigate();

	useEffect(() => {
		fetchData();
	}, [location]);

	const isExternal = (url) => {
		var match = url.match(/^([^:\/?#]+:)?(?:\/\/([^\/?#]*))?([^?#]+)?(\?[^#]*)?(#.*)?/);
		if (url.toLowerCase().indexOf('.pdf') > -1) return true;
		if (typeof match[1] === "string" && match[1].length > 0 && match[1].toLowerCase() !== window.location.protocol) return true;
		if (typeof match[2] === "string" && match[2].length > 0 && match[2].replace(new RegExp(":("+{"http:":80,"https:":443}[window.location.protocol]+")?$"), "") !== window.location.host) return true;
		return false;
	}

	const htmlContentClickHandler = (event) => {
		let linkEl = event.target.closest('a');
		if (linkEl) {
			let linkUrl = linkEl.getAttribute('href');

			if (!isExternal(linkUrl) && (!linkEl.getAttribute('target'))) {
				event.preventDefault();
				navigate(linkUrl);
			}
			else {
			}
		}
	}

	const fetchData = () => {
		setData(false);

		window.scrollTo(0, 0);

		//let path = this.props.url ? this.props.url : this.props.location.pathname;
		let path = location.pathname;

		// Sæki gögn til APA
		fetch(url+'?url='+path)
			.then(function(response) {
				return response.json();
			})
			.then(function(json) {
				/*
				this.setState({
					data: json.results[0],
					willRefresh: false,
					notFound: json.length == 0
				});

				if (json.results[0] && json.results[0].title) {
					window.document.title = config.siteTitle+' | '+json.results[0].title;
				}
				*/

				setData(json.results[0]);

				setTimeout(function() {
					setInitialized(true);
				});
			});
	}

	const formatHtml = (html) => {
		let formatedHtml = html.replace(/\\r|\\n/g, '');

		return formatedHtml;
	}

	return <div className={'grid grid-cols-1 place-items-center'+(initialized ? ' initialized' : '')}>
		{
			data &&

			<div className={'ml-4 mr-4 lg:ml-0 lg:mr-0 lg:w-3/4 mt-6'}>
				{
					/*
					dataItem.path.length > 1 &&
					<BreadCrumbs path={dataItem.path} />
					*/
				}
				{
					/*
					data.url != '/' &&
					<h1>{data.title}</h1>
					*/
				}
				<div className={'html-content font-junicode'+(location.pathname != '/' ? ' indent-6' : '')}
					ref={htmlContainer}
					onClick={htmlContentClickHandler}
					dangerouslySetInnerHTML={{__html: formatHtml(data.content)}}/>
			</div>
		}
		{
			/*
			this.state.notFound &&
			<div className="page-content">
				<h2>Síða finnst ekki</h2>
				<p>Engin síða finnst á slóðinni <strong>{this.props.history.location.pathname}</strong>.</p>
			</div>
			*/
		}
	</div>

}

export default PageView;
