import React, { Component } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import _ from 'underscore';
import GraphView from './GraphView';
import utils from './../utils/graphUtils';
import config from './../config';

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();

		return (
			<Component
				{...props}
				location={location}
				params={params}
				navigate={navigate}
			/>
		);
	}

	return ComponentWithRouterProp;
}

class WordGraphView extends Component {
	constructor(props) {
		super(props);

		this.relTypes = [
			{
				label: 'Sjá',
				type: 'SJA'
			},
			{
				label: 'Skylt',
				type: 'SK'
			},
			{
				label: 'Samanber',
				type: 'SBR'
			},
			{
				label: 'Athuga',
				type: 'ATH'
			},
			/*
			{
				label: 'Tökuorð',
				type: 'TOKUORD'
			},
			{
				label: 'Komið úr',
				type: 'KOMID_UR'
			}
			*/
		]

		this.state = {
			graphData: {},
			selectedRelTypes: ['SJA', 'SK', 'SBR', 'ATH'],
			flettur: true
		};
	}

	componentDidMount() {
		if (this.props.flettaId) {
			this.getData(this.props.flettaId);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.flettaId && this.props.flettaId != prevProps.flettaId) {
			this.getData(this.props.flettaId)
		}
	}

	getData(flettaId) {
		fetch(config.neo4jApi+'/ordsifjar/?flid='+flettaId+'&type='+(this.state.selectedRelTypes.length > 0 ? this.state.selectedRelTypes : ['NONE']).join(',')+(this.state.flettur ? '&flettur=true' : ''))
			.then((result) => result.json())
			.then((json) => {
				this.setState({
					graphData: utils.groupGraphData(json)
				});
			});
	}


	render() {
		return <div className="relative">
			<GraphView straightLines={true}
				distance={200}
				graphData={this.state.graphData}
				disableZoom={false}
				highlightId={[this.props.flettaId]}
				mainId={this.props.flettaId}
				showForeignLanguages={!this.state.flettur}
				onNodeClick={function(d) {
					if (d.properties.id) {
						let url = '/faersla/'+d.properties.id+'/net';
						if (this.props.isEmbed) {
							window.open(url, '_parent')
						}
						else {
							this.props.navigate(url);
						}
					}
					//this.props.history.push(helpers.routerUrls[d.label.toLowerCase()]+d.properties.ismus_id.low+(this.props.tabname ? '/'+this.props.tabname : ''));
				}.bind(this)}
				enableFullView={true}>

					<div className="flex gap-2 mb-2">
						<button className={'flex '+(!this.state.flettur ? '!bg-blue !text-white' : '')} onClick={() => {
							this.setState({
								flettur: !this.state.flettur
							}, () => {
								this.getData(this.props.flettaId);
							});
						}}>Sýna tengingar í gegnum erlend mál
							{
								!this.state.flettur &&
								<div className="inline-block ml-2">
									<svg style={{height: 18}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
										<path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
									</svg>
								</div>
							}
						</button>
					</div>

					<div className={'flex gap-2'+(this.state.flettur ? '' : ' hidden')}>
						{
							this.relTypes.map(type => <button key={type.type} className={this.state.selectedRelTypes.indexOf(type.type) > -1 ? '!bg-blue !text-white' : 'line-through'} onClick={() => {
								let selectedRelTypes = this.state.selectedRelTypes;

								if (selectedRelTypes.indexOf(type.type) == -1) {
									selectedRelTypes.push(type.type);
								}
								else {
									selectedRelTypes.splice(selectedRelTypes.indexOf(type.type), 1);
								}

								this.setState({
									selectedRelTypes: selectedRelTypes
								}, () => {
									this.getData(this.props.flettaId);
								});
							}}>{type.label}</button>)
						}
					</div>

			</GraphView>

			{
				!this.state.flettur && <div className="absolute left-0 bottom-0 right-0 p-3 text-sm bg-gray-darker text-white border border-1 border-gray shadow">
					Tengsl milli orða í gegnum erlend mál eru lesin vélrænt upp úr textum orðsifjabókarinnar og geta í einhverjum tilfellum gefið ranga mynd.
				</div>
			}
		</div>
	}
}


export default withRouter(WordGraphView);
