import React, { Component } from 'react';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import _ from 'underscore';
import helpers from './../utils/helpers';

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();

		return (
			<Component
				{...props}
				location={location}
				params={params}
				navigate={navigate}
			/>
		);
	}

	return ComponentWithRouterProp;
}

class TabContainer extends Component {
	constructor(props) {
		super(props);

		let children = _.filter((this.props.children instanceof Array ? this.props.children : [this.props.children]), (child) => child && !child.props.hideAsTab);

		this.state = {
			selectedTab: this.props.selectedTab || (this.props.useRouter && children.length > 0 ? children[0].props.tabname : 0)
		};
	}

	componentDidUpdate(prevProps, prevState) {
		let children = _.filter((this.props.children instanceof Array ? this.props.children : [this.props.children]), (child) => child && !child.props.hideAsTab);
		let prevChildren = _.filter((prevProps.children instanceof Array ? prevProps.children : [prevProps.children]), (child) => child && !child.props.hideAsTab);

		if (prevChildren.map((c) => c.props.tabtitle).join('-') != children.map((c) => c.props.tabtitle).join('-')) {
			let children = _.filter((this.props.children instanceof Array ? this.props.children : [this.props.children]), (child) => child && !child.props.hideAsTab);

			this.setState({
				selectedTab: this.props.selectedTab || (this.props.useRouter && children.length > 0 ? children[0].props.tabname : 0)
			}, () => {
				if (this.props.onSelectedTabChange) {
					this.props.onSelectedTabChange(this.state.selectedTab)
				}
			});
		}

		if (this.props.useRouter && this.props.selectedTab != prevProps.selectedTab) {
			this.setState({
				selectedTab: this.props.selectedTab || children[0].props.tabname
			}, () => {
				if (this.props.onSelectedTabChange) {
					this.props.onSelectedTabChange(this.state.selectedTab)
				}
			});
		}
	}

	formatBaseUrl() {
		return this.props.baseUrl.substr(this.props.baseUrl.length-1, 1) == '/' ? this.props.baseUrl.substr(0, this.props.baseUrl.length-1) : this.props.baseUrl;
	}

	render() {
		let children = _.filter((this.props.children instanceof Array ? this.props.children : [this.props.children]), (child) => child && !child.props.hideAsTab);

		return children.length > 0 ? <div className="tabs-wrapper">
			<ul className={'nav nav-tabs '+(this.props.alignTabs ? (this.props.alignTabs == 'center' ? 'justify-center' : this.props.alignTabs == 'end' ? 'justify-end' : '') : '')}>
				{
					_.filter(children, (child) => child && !child.props.hideAsTab).map(function(child, index) {
						if (child && child.props.tabtitle) {
							return <li key={index} className={'nav-item'+(child.props.tabname ? ' tab-'+child.props.tabname : '')}>
								<a className={'nav-link '+(this.state.selectedTab == (this.props.useRouter ? child.props.tabname : index) ? 'active' : '')} onClick={function() {
									if (this.props.useRouter) {
										let paramStr = '';
										if (this.props.preserveSearchParams) {
											let params = helpers.parseSearchParams(this.props.location.search);

											if (params.sida) {
												delete params.sida;
											}

											paramStr = helpers.searchParamString(params);
										}
										this.props.navigate(this.formatBaseUrl()+'/'+child.props.tabname+paramStr, { replace: true });
									}
									else {
										this.setState({
											selectedTab: index
										}, () => {
											if (this.props.onSelectedTabChange) {
												this.props.onSelectedTabChange(this.state.selectedTab)
											}
										});
									}
								}.bind(this)}>{child.props.tabtitle}</a>
							</li>;
						}
					}.bind(this))
				}
			</ul>

			{
				_.filter(children, (child) => child && !child.props.hideAsTab).map(function(child, index) {
					if (child) {
						return <div style={{marginTop: -1}} key={this.props.useRouter ? child.props.tabname : index} className={'tab'+(this.state.selectedTab == (this.props.useRouter ? child.props.tabname : index) ? ' selected' : '')}>
								{child}
							</div>;
						}
					}.bind(this))
				}
		</div> : null;
	}
}

export default withRouter(TabContainer);
