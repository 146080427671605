import { useState, useEffect, useRef } from 'react';
import { BrowserRouter, Routes, Route, Outlet, Link, useNavigate, useLocation } from "react-router-dom";

import SearchBox from './components/SearchBox';
import SearchResults from './components/SearchResults';
import EntryView from './components/EntryView';
import TengingarView from './components/TengingarView';
import PageView from './components/PageView';
import SkammstView from './components/SkammstView';
import PageMenu from './components/PageMenu';

import logo from './img/sam-logo.png';
import ordsifjabokLogo from './img/ordsifjabok.svg';

function Layout(props) {
	return <div className="container mx-auto">

		<div className="w-full _w-5/6">
			{
				props.children
			}
		</div>

	</div>
}

function App() {
	const [menuVisible, setMenuVisible] = useState(false);
	const location = useLocation();

	const menuButton = useRef(null);
	const menuContent = useRef(null);

	useEffect(() => {
		document.addEventListener('mousedown', (event) => {
			if (menuButton.current.contains(event.target)) {
				event.stopPropagation();
				return;
			}

			if (menuContent.current && !menuContent.current.contains(event.target)) {
				setMenuVisible(false);
			}
		});

		if (!window.showMenu) {
			window.showMenu = () => setMenuVisible(true)
		}

		if (!window.hideMenu) {
			window.hideMenu = () => setMenuVisible(false)
		}
	}, [])

	const isEmbed = location.pathname.indexOf('/embed_') > -1;

	return (

		<div className={'flex'+(isEmbed ? ' h-screen w-screen overflow-hidden pt-5 border-t border-b border-gray' : '')}>
			<Routes>
				<Route path="/embed_faersla/:entryId/:tab" element={<EntryView />} />
				<Route path="/embed_faersla/:entryId" element={<EntryView />} />
				<Route path="*" element={
					<>
						<div className={'relative w-1/4 min-w-[300px] max-w-[300px] transition-transform duration-500 z-20 '+(menuVisible ? 'md:translate-x-0' : 'translate-x-[-115%] md:translate-x-0')}>

							<div ref={menuContent} className="sticky top-0 container mx-auto text-white flex flex-col bg-arnastofnun py-12 min-h-screen shadow-2xl md:shadow-none">

								<div className="header px-10 py-8 pb-7">
									<Link to="/"><img className="w-full" src={ordsifjabokLogo} /></Link>
								</div>

								<PageMenu className="mx-6 mt-12 flex-grow" btnLinks={false} />

								<img src={logo} className="w-[130px] self-center" />

							</div>
						</div>

						<div className="w-full h-full overflow-y-scroll md:overflow-y-auto fixed md:relative md:w-3/4 flex flex-col min-h-screen">

							<SearchBox>
								<button ref={menuButton} onClick={() => setMenuVisible(!menuVisible)} className={'relative justify-self-end block md:hidden space-y-2 transition-transform hover:scale-110 z-50 pr-4'}>
									<div className={'transition-all w-8 h-0.5  '+(menuVisible ? 'origin-[2px] rotate-45 bg-arnastofnun' : 'bg-arnastofnun')}></div>
									<div className={'transition-all w-8 h-0.5 bg-arnastofnun '+(menuVisible ? 'opacity-0 ' : 'opacity-1')}></div>
									<div className={'transition-all w-8 h-0.5 '+(menuVisible ? 'origin-[2px] -rotate-45 bg-arnastofnun' : 'bg-arnastofnun')}></div>
									{/*
									<div className="w-8 h-0.5 bg-white"></div>
									<div className="w-8 h-0.5 bg-white"></div>
									<div className="w-8 h-0.5 bg-white"></div>
									*/}
								</button>
							</SearchBox>

							<div className="mx-6 grow">
								<Routes>
									<Route path="/" element={<PageView />} />
									<Route path="/leit/">
										<Route path=":searchTerm" element={<Layout><SearchResults /></Layout>} />
										<Route path="" element={<Layout><SearchResults /></Layout>} />
									</Route>
									<Route path="/faersla/:entryId/:tab" element={<Layout><EntryView /></Layout>} />
									<Route path="/faersla/:entryId" element={<Layout><EntryView /></Layout>} />
									<Route path="/skammstafanir/:tab" element={<Layout><SkammstView /></Layout>} />
									<Route path="/skammstafanir" element={<Layout><SkammstView /></Layout>} />
									<Route path="/tengingar/" element={<Layout><TengingarView /></Layout>} />
									<Route exact path="/*" element={<PageView />} />
								</Routes>
							</div>

							<div className="bg-gray-light px-4 py-12 mt-8">
								<div className="container mx-auto text-center text-gray-darker">
									<p className="text-lg">Stofnun Árna Magnússonar í íslenskum fræðum</p>
									<p>Arngrímsgötu 5, 107 Reykjavík</p>
								</div>
							</div>

						</div>
					</>
				} />

			</Routes>
		</div>
	);
}

export default App;
